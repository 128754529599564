module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-B9DKLDN6EB","anonymize":true},"facebookPixel":{"pixelId":"000000000","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Veneno Crowdfunding","short_name":"veneno","start_url":"/","lang":"es","background_color":"#000","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","localize":[{"start_url":"/es/","lang":"es","name":"Veneno","short_name":"veneno","description":"Sé parte de la creación del espacio más vibrante de Valencia. Arte, cultura, creatividad y gastronomía en el barrio de Ruzafa."},{"start_url":"/en/","lang":"en","name":"Veneno","short_name":"veneno","description":"Be part of the creation of Valencia most vibrating place. Art, culture, creativity and gastronomy in Ruzafa."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef755f61607f0f6b256613da0520856d"},
    },{
      plugin: require('../node_modules/@herob191/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://www.venenoconcept.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
